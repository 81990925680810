

















































































































































































import { defineComponent, reactive, ref } from '@vue/composition-api';
import Pagination from 'vue-pagination-2';
import chunk from 'lodash/chunk';

export default defineComponent({
  components: {
    Pagination,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    rows: {
      required: true,
      type: Array as any,
    },
  },
  setup(props) {
    const expandings = ref([]);
    const currentPage = 1;
    const recordPerPage = 10;
    const pageChunks = chunk(props.rows, recordPerPage);
    const displayRows = reactive([...pageChunks[currentPage - 1]]);
    const paginationOptions = {
      theme: 'bulma',
      edgeNavigation: true,
      texts: {
        count: 'หน้าที่ {page} / {pages}',
      },
      // texts: 'หน้า {page} รายการที่ {from} ถึง {to} จาก {count} รายการ|{count} records|One record',
    };

    const expandRow = index => {
      const existI = expandings.value.findIndex(j => j === index);
      if (existI > -1) {
        expandings.value.splice(existI, 1);
      } else {
        expandings.value.push(index);
      }
    };

    const formatNumber = str => new Intl.NumberFormat().format(str);
    const formatDate = date => {
      if (date === null) {
        return '-';
      }
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      return (new Date(date)).toLocaleDateString('th-TH', options);
    };
    const grandTotalPriceAgree = () => {
      if (props.rows.length > 0) {
        return props.rows.map(row => row.sum_price_agree)
          .reduce((accumulator, value) => accumulator + value, 0);
      }
      return 0;
    };
    const staticMapImageUrl = (lat, lon) => {
      if (lat == null || lon == null) {
        return null;
      }
      let url = 'https://maps.googleapis.com/maps/api/staticmap?';
      url += `center=${lat},${lon}&`;
      url += 'zoom=13&size=500x260&maptype=roadmap&markers=color:red%7C';
      url += `${lat},${lon}`;
      url += '&key=AIzaSyDL1SZ0Z_EolKW_tFJaUTW27dyb6spjP3k';
      return url;
    };
    const googleMapLink = (lat, lon) => `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`;
    const navigate = page => {
      displayRows.splice(0, displayRows.length);
      displayRows.push(...pageChunks[page - 1]);
    };

    return {
      recordPerPage,
      currentPage,
      expandings,
      expandRow,
      formatNumber,
      formatDate,
      grandTotalPriceAgree,
      staticMapImageUrl,
      googleMapLink,
      paginationOptions,
      navigate,
      displayRows,
    };
  },

});
